:root {
    --color-hr-red: #b73244;
    --color-hr-red-dark: #af2336;
    --color-hr-red-darker: #801e29;
    --color-hr-red-light: rgba(183, 50, 68, 0.25);
    --color-dark-grey: #454545;
    --color-light-grey: #D9D9D9;
    --color-failure-bg: #f8d7da;
    --color-failure-border: #f5c6cb;
}

.styled-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.styled-scrollbar::-webkit-scrollbar-track {
    background: var(--color-light-grey);
    border-radius: 4px;

}

/* Handle */
.styled-scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-hr-red);
    border-radius: 4px;
}

/* Handle on hover */
.styled-scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--color-hr-red-dark);
}

/*.styled-scrollbar {*/
/*    -ms-overflow-style: none;*/
/*    scrollbar-width: thin;*/
/*}*/
header {
    height: 100%;
}

.map-page {
    width: 100%;
    height: 100%;
}

#map {
    width: 100%;
    height: 100%;
}

.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    font-family: Arial, sans-serif;
    color: var(--color-dark-grey);
}

.user-info {
    text-align: center;
    margin-bottom: 24px;
}

.user-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 16px;
}

.username {
    margin-bottom: 8px;
}

.full-name {
    margin-bottom: 4px;
}

.email {
    font-size: 14px;
}

.domains-section,
.churches-section {
    margin-bottom: 32px;
}

.domains-section h3,
.churches-section h3 {
    border-bottom: 2px solid var(--color-hr-red);
    display: inline-block;
    padding-bottom: 4px;
    margin-bottom: 16px;
}

.react-datepicker__time-list {
    display: flex;
    flex-direction: column;
}

.truncate-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-info-window {
    min-width: 300px;
    height: 195px;
}

.map-info-window-header {
    color: #454545;
    font-weight: 800;
    font-size: 20px;
    white-space: nowrap;
    line-height: 22px;
}

.map-info-window-subheader {
    color: #919191;
    font-weight: 800;
    line-height: 22px;
}

.separater {
    border: 1px solid #DDDDDD;
    width: 100%;
}