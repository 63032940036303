.tab-nav {
    background: #fdfdfd;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #eeeeee;
    height: 44px;

}

.tab-ul,
.tab-li {
    height: 100%;
    list-style: none;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    min-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

ul {
    display: flex;
}

li {
    /*padding: 10px 15px;*/
    position: relative;
    background: white;
    cursor: pointer;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;
    user-select: none;
}

.underline {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--color-hr-red);
}

li.selected {
    background: #eee;
}

li button {
    /*width: 20px;*/
    height: 20px;
    border: 0;
    background: #fff;
    border-radius: 3px;
    display: flex;
    stroke: #000;
    cursor: pointer;
    flex-shrink: 0;
}