.checkboxes {
    max-height: 200px;
    padding: 10px;
    overflow: scroll;
    background: #d9d9d9;
    border-radius: 5px;
}

.checkboxes[data-isOpen="true"] {
    max-height: 75px;
}